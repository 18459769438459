import { ConsentOptions } from 'react-hook-consent'

import {
  GOOGLE_ADS_ID,
  GOOGLE_TRACKING_ID,
  TWITTER_CONVERSION_ID,
} from './constants'

const twitterServiceCode = TWITTER_CONVERSION_ID
  ? `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','${TWITTER_CONVERSION_ID}');`
  : ``

export const consentOptions: ConsentOptions = {
  services: [
    {
      id: 'required',
      name: 'Essential cookies',
      description:
        'Essential cookies are necessary for the website to function and cannot be switched off in our systems. They enable basic functions such as security, network management, and accessibility They are usually only set in response to actions made by you, such as setting your privacy preferences, logging in, or completing online forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not function properly if you do so.',
      scripts: [],
      mandatory: true,
    },
    /* NOTE: no functional cookies at the moment; remove until needed
    {
      id: 'functional',
      name: 'Functional cookies',
      description:
        'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
      scripts: [],
    },
    */
    {
      id: 'analytics',
      name: 'Analytics cookies',
      description:
        'Analytics cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.',
      scripts: [
        {
          id: 'google-tag-manager-script',
          src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TRACKING_ID}`,
        },
        {
          id: 'google-analytics-script',
          code: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${GOOGLE_TRACKING_ID}', {'send_page_view': false});
              gtag('config', '${GOOGLE_ADS_ID}');
            `,
        },
      ],
    },
    {
      id: 'targeting-advertising',
      name: 'Targeting and advertising cookies',
      description:
        'Targeting cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites.',
      scripts: [
        {
          id: 'inline-code',
          code: `
              ${twitterServiceCode}
            `,
        },
      ],
    },
  ],
}
