import Link from 'next/link'
import React from 'react'
import Image from 'next/image'
import styled from '@emotion/styled'
import { ConsentBanner, useConsent } from 'react-hook-consent'

const StyledButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
`

export const ToggleGDPRConsentSettings = () => useConsent().toggleDetails

export const GDPRConsentSettingsButton = () => {
  const toggleGDPRConsentSettings = ToggleGDPRConsentSettings()
  return (
    <StyledButtonWrapper>
      <button onClick={toggleGDPRConsentSettings}>
        <Image
          src={'/tools.svg'}
          alt="GDPR Settings"
          width={24}
          height={24}
          className="hover:border hover:rounded-lg"
        />
      </button>
    </StyledButtonWrapper>
  )
}

export const GDPRConsentBanner = () => {
  return (
    <>
      <ConsentBanner
        decline={{ hidden: false, label: 'Decline' }}
        settings={{
          modal: {
            decline: { hidden: true },
            description: (
              <>
                We use cookies and third party services on this website. Some of
                them are essential, others help us to improve your browsing
                experience. Feel free to adjust your individual settings below.{" "}
                <Link href="/cookie-policy-web" target="_blank">
                  Cookie Policy
                </Link>
              </>
            ),
          },
        }}
      >
        We use a selection of our own and third-party cookies & providers on the
        pages of this website. If you choose &quot;Approve&quot;, you consent to
        the use of all cookies. You can accept and reject individual cookie
        types and revoke your consent for the future at any time under
        &quot;Settings&quot;.{" "}
        <Link href="/cookie-policy-web" target="_blank">
          Cookie Policy
        </Link>
      </ConsentBanner>
      <GDPRConsentSettingsButton />
    </>
  )
}
