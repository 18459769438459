import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { getReferral } from '../../lib/referrals'
import { useGa } from '../../providers/GaProvider'
import { Footer } from '../Footer'
// import { Header } from '../Header'

interface MainContainerProps {
  referral?: string
}

export const MainContainer = ({
  children,
  referral,
}: PropsWithChildren<MainContainerProps>) => {
  const router = useRouter()
  const hideElements = router.pathname == '/post-order/[orderId]'
  const ga = useGa()
  const [verifiedReferral, setVerifiedReferral] = useState(referral)

  let referralQuery = router.query.referral
  if (Array.isArray(referralQuery) && referralQuery.length > 0) {
    referralQuery = referralQuery[0]
  } else {
    referralQuery = referralQuery || ''
  }

  useEffect(() => {
    if (!router.isReady) return

    const handleGetReferral = async () => {
      const referralResponse = await getReferral(referralQuery as string)
      if (referralResponse.success) {
        setVerifiedReferral(referralResponse.referral)
      }
    }

    if (
      referralQuery &&
      (!verifiedReferral || referralQuery !== verifiedReferral)
    ) {
      handleGetReferral()
    }
  }, [referral, router.isReady, referralQuery, verifiedReferral])

  useEffect(() => {
    if (!router.isReady) return

    ga('event', 'page_view', { referral })
    // Add referral id in customDimension/custom-event referrals
    ga('event', 'referrals', { referral_id: referral })
    // Add customDimension with user as scope to get referral_id from realtime
    ga('set', { user_properties: { referral_id: referral } })
  }, [ga, referral, router.isReady])

  return (
    <div
      className={`main-container px-[16px] lg:px-[75px] w-full pt-0 py-[24px] pb-0 min-h-[100dvh] relative ${
        hideElements ? 'h-[1px]' : 'h-auto'
      }`}
    >
      <div className="max-w-[1440px] m-auto min-h-[100dvh] flex flex-col h-full">
        {children}
        {!hideElements && <Footer />}
      </div>
    </div>
  )
}
